import React, { useContext, useEffect } from "react";
import CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";
import { withRouter } from "react-router-dom";
import { GlobalContext } from "context/globalContext/globalContext";
const phpUnserialize = require("phpunserialize");

const Authentication = (props: any) => {
  const { history } = props;
  const { dispatch } = useContext(GlobalContext);

  // handler to decode the login data
  const decodeAuthHandler = () => {
    if (history?.location?.search.includes("?result=")) {
      let encrypted: any = history?.location.search?.replace("?result=", "");
      //  REACT_APP_ENCRIPTED_KEY
      let key: any = process.env.REACT_APP_ENCRIPTED_KEY;
      //  Laravel creates a JSON to store iv, value and a mac and base64 encodes it.
      // So let's base64 decode the string to get them.
      encrypted = atob(encrypted);
      encrypted = JSON.parse(encrypted);
      //  IV is base64 encoded in Laravel, expected as word array in cryptojs
      const iv = Base64.parse(encrypted.iv);
      // Value (chipher text) is also base64 encoded in Laravel, same in cryptojs
      const value = encrypted.value;
      //  Key is base64 encoded in Laravel, word array expected in cryptojs
      // Key is base64 encoded in Laravel, word array expected in cryptojs
      key = CryptoJS.enc.Base64.parse(key);
      // Decrypt the value, providing the IV.
      let decrypted: any = CryptoJS.AES.decrypt(value, key, {
        iv: iv,
      });
      // CryptoJS returns a word array which can be
      // converted to string like this
      decrypted = decrypted.toString(CryptoJS.enc.Utf8);
      // unserializedJson
      const unserializedJson = phpUnserialize(decrypted);
      // response
      const response = {
        ...unserializedJson,
        data: JSON.parse(unserializedJson?.data),
        content: JSON.parse(unserializedJson?.content),
      };
      console.log(":response", response);
      // condition to redirect for signup page
      if (
        response?.data?.message?.message ===
          "Authentication succeeded, now register your phone number" ||
        response?.data?.error?.error ===
          "You haven't registered your phone number, please register your phone number and verify it then complete your profile info."
      ) {
        // global state to set their status for loggedIn process
        dispatch({
          type: "SET_LOGGEDIN_USER_STATUS",
          payload: response,
        });
        history.push("/signup");
      }
    }
  };
  //   useEffect to call decoded handler
  useEffect(() => {
    decodeAuthHandler();
  }, []);
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 h-screen">
      <span></span>
    </div>
  );
};

export default withRouter(Authentication);
