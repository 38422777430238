import React, { useEffect } from "react";
import MyRouter from "routers/index";
import { GlobalProvider } from "context/globalContext/globalContext";

function App() {
  useEffect(() => {
    const fetchApi = async () => {
      const url = `http://api.openweathermap.org/data/2.5/weather?q=dubai&units=metric&appid=4ebb9418ca605fa1931880e565ec065c`;
      const response = await fetch(url);
      const resJson = await response.json();
      // setCity(resJson.main);
      console.log("resJson", resJson);
    };
    fetchApi();
  }, []);
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <GlobalProvider>
        
        <MyRouter />
      </GlobalProvider>
    </div>
  );
}

export default App;
