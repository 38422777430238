import React, { useState } from "react";
import AppleSvg from "images/Apple.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Form } from "antd";
import SignupVerificationModal from "shared/modals/signupVerificationModal";
import { PostAPIService } from "context/services";
import { notification } from "antd";

const loginSocials = [
  {
    name: "Continue with Apple",
    href: "#",
    icon: AppleSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];
// notification
type NotificationType = "success" | "info" | "warning" | "error";

const PageLogin = (props: any) => {
  const { className, history }: any = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState({});
  const [verificationModal, setVerificationModal] = useState("");
  const [selectedAuthentication, setSelectedAuthentication] = useState("");
  const [current, setCurrent] = useState(0);
  // notification popup
  const [api, contextHolder] = notification.useNotification();

  // notification modal
  const openNotification = (
    type: NotificationType,
    message: string,
    description: string
  ) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  // handler to show verification status for user that he/she didn't completed
  const userProfileHandler = (values: any, error: any) => {
    if (
      error?.response?.data?.error ===
      "Your email is not verified, check your inbox for the verification code or resend it, or sign in via Google or Apple account with the same registered email."
    ) {
      setUser(values);
      setIsModalOpen(true);
      setVerificationModal("custom");
      setLoader(false);
    } else if (
      error?.response?.data?.error ===
      `You haven't registered your phone number, please register your phone number and verify it then complete your profile info.`
    ) {
      setUser(values);
      setIsModalOpen(true);
      setVerificationModal("custom");
      setLoader(false);
      setCurrent(1);
    } else if (
      error?.response?.data?.error ===
      `You have to complete your profile information before logging in.`
    ) {
      setUser(values);
      setIsModalOpen(true);
      setVerificationModal("custom");
      setLoader(false);
      setCurrent(2);
    }
  };

  const onFinish = (values: any) => {
    setLoader(true);
    PostAPIService("/login?locale=en", values)
      .then((response) => {
        console.log("response", response);
        setLoader(false);
        openNotification(
          "success",
          "Success",
          "You have logged in successfully."
        );
        history.push("/");
      })
      .catch((error: any) => {
        setLoader(false);
        console.log("error.response.data.error", error.response.data.error);
        userProfileHandler(values, error);
        openNotification(
          "error",
          "Error",
          error.response.data.message
            ? error.response.data.message
            : error.response.data.error
        );
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      {/* page */}
      <Helmet>
        <title>Login</title>
      </Helmet>
      {/* modal */}
      <SignupVerificationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        verificationModal={verificationModal}
        openNotification={openNotification}
        user={user}
        setUser={setUser}
        selectedAuthentication={selectedAuthentication}
        history={history}
        current={current}
        setCurrent={setCurrent}
      />
      {/* notification popup */}
      {contextHolder}
      {/*  */}
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:bg-[#d9dff2]"
                onClick={() => setIsModalOpen(true)}
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            className="max-w-full"
          >
            {/* email */}
            <Form.Item
              label=""
              name="email"
              rules={[{ required: true, message: "Please enter your email!" }]}
              className="antd-label-container"
            >
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Email address
                </span>
                <Input
                  type="email"
                  placeholder="example@example.com"
                  className="mt-1"
                />
              </label>
            </Form.Item>
            {/* password */}
            <Form.Item
              label=""
              name="password"
              rules={[
                { required: true, message: "Please enter your password!" },
              ]}
              className="antd-label-container"
            >
              <label className="block">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  Password
                </span>
                <Input type="password" className="mt-1" />
              </label>
            </Form.Item>
            {/* button */}
            <div className="flex justify-center">
              <ButtonPrimary type="submit">
                {loader ? "...Wait" : "Continue"}
              </ButtonPrimary>
            </div>
          </Form>
          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup">Create an account</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
