const Reducer = (state: any, action: any) => {
    switch (action.type) {
      case "SET_LOGGEDIN_USER_STATUS":
        return {
          ...state,
          loggedInUserStatus: action.payload,
        };
      default:
        return state;
    }
  };
  export default Reducer;
  